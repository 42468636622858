import React from 'react';
import assets from "../../config/assets";
export default function Item({question = "", siteId, options = [], checked, onChange, correctIndex = null, ...props}) {

  return (
    <div className={`poll__dialog poll__dialog--${siteId}`}>
      <div className="poll__inner">
        <div className="poll__question">
          <h1>{question}</h1>
        </div>
        <div className="poll__answers">
          {
            options.map(({text, label:name}, index) => {
              return (
                <div key={index} className={`poll__answers-answer poll__answers-answer--${checked === name ? 'checked' : 'unchecked'}`}>
                  <input type="checkbox" name={name} checked={checked === name} onChange={onChange} {...props}/>
                  <p>
                    {text}
                  </p>
                  <img className="poll__answers-answer-checkmark" src={assets.checkmark}/>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
