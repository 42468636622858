import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./app.scss";
import Loading from "../loading/loading";
import Auth from "../auth/auth";
import Poll from "../poll/poll";
import Screen from "../screen/screen";

const Home = lazy(() => import("../../screens/home/home"));
const Campaign = lazy(() => import("../../screens/campaign/campaign"));
const Login = lazy(() => import("../../screens/login/login"));
const Dashboard = lazy(() => import("../../screens/dashboard/dashboard"));
const NotFound = lazy(() => import("../../screens/not-found/not-found"));


export default function App() {

  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route exact path="/:url" element={<Campaign />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/admin/dashboard" element={<Auth><Dashboard /></Auth>} />
          <Route exact path="/admin/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      <Poll/>
    </div>
  );
}
