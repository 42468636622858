const PROVIDER = window.localStorage;

// @note security feature to prevent third-party scripts from reading sensitive data from localStorage
delete window.localStorage;

// Prevent console errors for libs that use localStorage
window.localStorage = {
  getItem(key) {
    return null;
  },
  setItem(key, value) {
    return null;
  },
  removeItem(key) {
    //
  }
};

export default {
  get(key, defaultValue = null) {
    const value = PROVIDER.getItem(key);
    if (value === null) {
      return defaultValue;
    }
    return JSON.parse(value);
  },
  set(key, value) {
    PROVIDER.setItem(key, JSON.stringify(value));
  },
  remove(key) {
    PROVIDER.removeItem(key);
  },
};
