import storage from "./storage";

const BASE_URL = "https://api.vimeo.com";
const BODY_METHODS = ["POST", "PATCH"];

async function sendRequest(endpoint, method, data = {}) {
  try {
    let body = undefined;
    if (BODY_METHODS.includes(method)) {
      body = JSON.stringify(data);
    }
    const res = await fetch(`${BASE_URL}${endpoint}`, {
      method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        Authorization: `bearer ${data.token}`,
      },
      body,
    });
    return await res.json();
  } catch (err) {
    console.log(err);
    return { error: "API failure" };
  }
}

export default {
  user() {
    const token = storage.get("token");
    return sendRequest("/me", "GET", { token });
  },
  videos() {
    const token = storage.get("token");
    return sendRequest("/me/videos", "GET", { token });
  },
};
