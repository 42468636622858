import slugify from 'slugify';
const ALPHABET = Array(10).fill().map((_, index) => `${index + 1}.`);
function questionOptionFactory({text = "", index}) {
  return {text, id: index + 1, label: ALPHABET[index]}
}
let id = 0;
function questionFactory({question, options = []}) {
  id++
  const formattedOptions = options.map((item, index) => questionOptionFactory({...item, index}));
  return {id: slugify(question), question, options: formattedOptions};
}
const QUESTIONS = {
  rm: [
    questionFactory({
      question: "Which statement most accurately reflects your stance towards a career in the Royal Marines?",
      options: [
        {
          text: "I won't consider an expression of interest or application for a career in the Royal Marines",
        },
        {
          text: "I want more time to consider an expression of interest or application for a career in the Royal Marines",
        },
        {
          text: "I have questions before submitting an expression of interest or application for a career in the Royal Marines"
        },
        {
          text: "I am ready to submit an expression of interest or application for a career in the Royal Marines",
        },
        {
          text: "I have already submitted an expression of interest or application",
        }
      ]
    }),
  ],
  rn: [
    questionFactory({
      question: "Which statement most accurately reflects your stance towards a career in the Royal Navy?",
      options: [
        {
          text: "I won't consider an expression of interest or application for a career in the Royal Navy"
        },
        {
          text: "I want more time to consider an expression of interest or application for a career in the Royal Navy",
        },
        {
          text: "I have questions before submitting an expression of interest or application for a career in the Royal Navy",
        },
        {
          text: "I am ready to submit an expression of interest or application for a career in the Royal Navy",
          correct: true
        },
        {
          text: "I have already submitted an expression of interest or application",
        }
      ]
    }),
    questionFactory({
      question: "How ready do you feel to submit an application for a career in the Royal Navy?",
      options: [
        {
          text: "I won't consider submitting an application for a career in the Royal Navy"
        },
        {
          text: "I want more time to consider an application for a career in the Royal Navy",
        },
        {
          text: "I require more information on lifestyle benefits (pay, leave, travel, sport etc) before submitting an application for a career in the Royal Navy",
        },
        {
          text: "I require more information on a specific role(s) before submitting an application for a career in the Royal Navy"
        },
        {
          text: "I am ready to submit an application and require no further information",
        }
      ]
    }),
  ],

  rnr: [
    questionFactory({
      question: "Which statement most accurately reflects your stance towards a career in the Royal Naval Reserve?",
      options: [
        {
          text: "I won't consider an expression of interest or application for a career in the Royal Naval Reserve"
        },
        {
          text: "I want more time to consider an expression of interest or application for a career in the Royal Naval Reserve",
        },
        {
          text: "I have questions before submitting an expression of interest or application for a career in the Royal Naval Reserve",
        },
        {
          text: "I am ready to submit an expression of interest or application for a career in the Royal Naval Reserve",
        },
        {
          text: "I have already submitted an expression of interest or application",
        }
      ]
    }),
  ]
}


export default QUESTIONS;
