import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import storage from "../../services/storage";
import api from "../../services/vimeo-api";
import Screen from "../screen/screen";
import Loading from "../loading/loading";

export default function Auth({ children }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [authenticating, setAuthenticating] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    api
      .user()
      .then((data) => {
        if (data.error) {
          return setAuthenticated(false);
        }
        storage.set("user", data);
        setAuthenticated(true);
      })
      .finally(() => {
        setAuthenticating(false);
      });
  }, []);

  useEffect(() => {
    if (authenticating) {
      return;
    }
    if (!authenticated && pathname !== "/admin/login") {
      return navigate("/admin/login");
    }
    if (authenticated && pathname !== "/admin/dashboard") {
      return navigate("/admin/dashboard");
    }
  }, [authenticated, authenticating]);

  if (authenticating) {
    return (
      <Screen name="authenticating">
        <Loading>Authenticating...</Loading>
      </Screen>
    );
  }

  return children;
}
