import storage from "./storage";

const BODY_METHODS = ["POST", "PATCH"];

async function sendRequest(endpoint, method, data = {}) {
  try {
    let body = undefined;
    if (BODY_METHODS.includes(method)) {
      body = JSON.stringify(data);
    }
    const res = await fetch(endpoint, {
      method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: storage.get("token"),
      },
      body,
    });
    return await res.json();
  } catch (err) {
    console.log(err);
    return { errors: ["API error"] };
  }
}

export default {
  campaign(id) {
    return sendRequest(`/api/campaign/${id}`, "GET");
  },
  campaigns() {
    return sendRequest("/api/campaign", "GET");
  },
  polls() {
    return sendRequest(`/api/polls`, "GET");
  },
  campaignDestroy(id) {
    return sendRequest(`/api/campaign/${id}`, "DELETE");
  },
  campaignChat(id) {
    return sendRequest(`/api/campaign/chat/${id}`, "POST");
  },
  setPollLive(id, data) {
    return sendRequest(`/api/campaign/${id}`, 'PATCH', data)
  },
  createPoll(data) {
    return sendRequest("/api/poll", "POST", data)
  }
};
