import "core-js/stable";
import "regenerator-runtime/runtime";
import React, { StrictMode } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { render } from "react-dom";
import AppContextProvider from "./components/app-context-provider/app-context-provider";
import App from "./components/app/app";

// @note Disabled due to some third party packages using old APIs
const ENABLE_STRICT_MODE = false;

function Wrapper({ children }) {
  if (!ENABLE_STRICT_MODE) {
    return children;
  }
  return <StrictMode>{children}</StrictMode>;
}

render(
  <Wrapper>
    <Router>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </Router>
  </Wrapper>,
  document.querySelector("#root")
);
