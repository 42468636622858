import moment from "moment";
import React, { useState, useEffect } from "react";
import { AppContext } from "../../config/context";
import storage from "../../services/storage";

const TEMPLATE_POLL_AVAILABLE_FOR = ['rn', 'rnr', 'rm'];

export default function AppContextProvider({ children }) {
  const [siteId, setSiteId] = useState(null);
  const [pollVisible, updatePollVisible] = useState(false);
  const [eventEndAt, setEventEndAt] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [pollTemplateIndex, setPollTemplateIndex] = useState(null);



  useEffect(() => {
    if(!siteId || !eventId || !eventEndAt || !TEMPLATE_POLL_AVAILABLE_FOR.includes(siteId)) return;
    const end = moment(eventEndAt);
    const pollsComplete = storage.get('poll-complete') ?? [];
    // This is a testing feat requested ->
    // Update the storage if the poll id is already saved.
    if(pollsComplete.includes(eventId) && moment().isBefore(end)) {
      return storage.set('poll-complete', pollsComplete.filter(id => id !== eventId));
    }
    const timer = setInterval(() => {
      const pollsComplete = storage.get('poll-complete') ?? [];
      // Exit-poll should only be visible after campaign ends and if user has not completed poll previously
      updatePollVisible(moment().isAfter(end) && !pollsComplete.includes(eventId));
    }, 1000);

    return () => {
      clearInterval(timer);
    }
  }, [eventEndAt, siteId, eventId]);

  return (
    <AppContext.Provider value={{ siteId, pollVisible, updatePollVisible, setEventEndAt, setSiteId, eventId, setEventId, pollTemplateIndex, setPollTemplateIndex }}>{children}</AppContext.Provider>
  );
}
