import React, {useContext} from "react";
import {AppContext} from "../../config/context";
import "./screen.scss";

export default function Screen({ name, children, style }) {
  const {pollVisible} = useContext(AppContext);
  return (
    <div className={`Screen Screen--${name} Screen--${pollVisible ? 'hidden' : "visible"}`} style={style}>
      <div className="Screen__container">{children}</div>
    </div>
  );
}
