import React, {useContext, useCallback, useState, useEffect} from 'react';
import assets from "../../config/assets";
import "./poll.scss"
import {AppContext} from "../../config/context";
import PollContext from "./context";
import Item from "./item";
import QUESTIONS from "./const";
import Button from "./button";
import api from "../../services/api";
import storage from "../../services/storage";

export default function Poll() {
  const {pollVisible, updatePollVisible, siteId, eventId, pollTemplateIndex} = useContext(AppContext);
  const [validate, updateValidate] = useState({});
  const [currentQIndex, updateCurrentQIndex] = useState(0);
  const [formData, updateFormData] = useState({})
  const context = {validate, updateValidate};
  const [error, setError] = useState(null);

  const handleClose = useCallback(() => {
    const currentPolls = storage.get('poll-complete')??[];
    currentPolls.push(eventId);
    storage.set('poll-complete', currentPolls);
    updatePollVisible(false);
  }, [eventId])

  const handleSubmit = useCallback(async (e) => {
    const currentPolls = storage.get('poll-complete')??[];
    e.preventDefault();

    try {
      const response = await api.createPoll({
        question: QUESTIONS[siteId][0].question,
        answer: Object.values(formData)[0],
        eventId,
      });
      currentPolls.push(eventId);
      response.errors && response?.errors?.server && setError(response.errors.server);
      !response.errors && storage.set('poll-complete', currentPolls);
      !response.errors && updatePollVisible(false);
    } catch (e) {
      console.log(e)
    }
  }, [formData, setError, eventId]);

  const handleInputChange = useCallback((e) => {
    const data = {};
    data[QUESTIONS[siteId][currentQIndex].id] = e.target.name;
    updateFormData((prev) => ({...prev, ...data}))
  }, [currentQIndex, siteId]);

  useEffect(()=>{

    if(siteId && QUESTIONS[siteId].length >= pollTemplateIndex){
      updateCurrentQIndex(pollTemplateIndex - 1);
    }

  },[pollTemplateIndex,siteId]);

  return (
    <PollContext.Provider value={context}>
      <form onSubmit={handleSubmit} className={`poll poll--${pollVisible ? "visible" : "hidden"}`}>
        <div className={`poll__container poll__container--${siteId}`}>
          <div className="poll-close" onClick={handleClose}>
            Close
          </div>
          {
            QUESTIONS[siteId]?.map(({question, options, id}, index) => {
              if (index !== currentQIndex) {
                return null
              }
              return (
                <Item key={index} {...{options, question, checked: formData[id]}} onChange={handleInputChange} siteId={siteId}/>
              )
            }) ?? null
          }

          <div className="poll__footer">
            <img src={assets.logo[siteId]}/>
            <div style={{width: "100%", display: "flex", justifyContent: "center", position: 'relative'}}>

              {error && <p className="error-message">{error}</p>}
              <Button type="submit" siteId={siteId}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>
    </PollContext.Provider>
  )
}
