import logoRN from "../assets/logo-rn.svg";
import logoRNR from "../assets/logo-rnr.svg";
import logoRM from "../assets/logo-rm.svg";
import logoRAF from "../assets/logo-raf.svg";
import taglineRn from "../assets/tagline-rn.svg";
import ctaHeader from "../assets/cta-header.svg";
import ctaRegister from "../assets/cta-register.png";
import ctaChat from "../assets/cta-chat.png";
import checkmark from "../assets/checkmark.svg";
export default {
  checkmark,
  logo: {
    rn: logoRN,
    rm: logoRM,
    rnr: logoRNR,
    raf: logoRAF,
  },
  tagline: {
    rn: taglineRn,
  },
  cta: {
    header: ctaHeader,
    register: ctaRegister,
    chat: ctaChat,
  },
};
